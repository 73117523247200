import { setup } from "@loomhq/record-sdk";
import { isSupported } from '@loomhq/record-sdk-supported';
import { oembed } from "@loomhq/loom-embed";

const PUBLIC_APP_ID = "4680b49a-0e7c-40ae-81b6-37bc5cfea072";
const BUTTON_ID = "record-button";

function insertEmbedPlayer(html) {
    const target = document.getElementById("video");

    if (target) {
        target.innerHTML = html;
    }
}

export async function init() {
    const { supported, error } = await isSupported();

    if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        return;
    }

    const button = document.getElementById(BUTTON_ID);

    if (!button) {
        return;
    }

    const { configureButton } = await setup({
        publicAppId: PUBLIC_APP_ID,
    });

    const sdkButton = configureButton({ element: button });

    sdkButton.on("insert-click", async (video) => {
        $('#response_video_url').val(video.sharedUrl);
        const { html } = await oembed(video.sharedUrl, { width: 400 });
        insertEmbedPlayer(html);
    });
}

export async function setVideoTag(width) {
    const videoTag = document.getElementById('video')

    if (!videoTag.dataset.url.includes('cdn.filestackcontent.com')) {
        const { html } = await oembed(videoTag.dataset.url, width != null ? { width: width } : {} );
        insertEmbedPlayer(html);
    }
}

function copyToClipboard(text_to_copy) {
    const $temp = $("<input>");
    if (!navigator.clipboard) {
        $("body").append($temp);
        $temp.val(text_to_copy).select();
        document.execCommand("copy");
        $temp.remove();
        showCopiedMessage()
    } else {
        navigator.clipboard.writeText(text_to_copy).then(function () {
            showCopiedMessage() // success
        })
            .catch(function () {
                alert("Fail to copy"); // error
            });
    }
}

function showCopiedMessage() {
    $('#copy-message').show(); // success
    setTimeout(() => {
        $('#copy-message').hide()
    }, 3000)
}

function cleanupDuplicatedElement() {
    $('.approved-box.dup').remove();
};

document.addEventListener('turbolinks:before-visit', cleanupDuplicatedElement);

document.addEventListener('turbolinks:load', function() {
    $(document).on('click', '#copy-my-link', () => {
        copyToClipboard($('#my-share-link').val())
    })

    const approvedBox = $('.approved-box:not(.dup)')
    const navBarTop = $('.navbar.fixed-top');
    const navBarBottom = $('.navbar.fixed-bottom');

    $(window).on('scroll', function (e) {
        const resultBody = $('.sidebar-grid .result-body');

        if (approvedBox.length < 1 || !navBarTop.length || !resultBody.length) {
            cleanupDuplicatedElement();
            return;
        }

        if(navBarBottom.length > 0) {
            $('body').css({paddingBottom: navBarBottom.outerHeight() + 'px'})
        }

        const offset = approvedBox.offset().top + approvedBox.outerHeight() - navBarTop.outerHeight();
        const offsetBottom = resultBody.offset().top + resultBody.outerHeight() - approvedBox.outerHeight();
        const dup = approvedBox[0].cloneNode(true);
        const computedStyle = window.getComputedStyle(approvedBox[0]);

        // Calculate the threshold based on offsetBottom
        const thresholdPercentage = 20;
        const threshold = (thresholdPercentage / 100) * offsetBottom;

        $(dup).addClass('dup')
            .css({
                position: 'fixed',
                bottom: navBarBottom.length > 0 ? navBarBottom.outerHeight() + 'px' : '10px',
                width: computedStyle.width,
                zIndex: '99',
            });

        const approveBoxDuplicated = $('.approved-box.dup');

        if (window.pageYOffset > offset && approveBoxDuplicated.length < 1) {
            $('.result-body').append(dup);
            resultBody.css({paddingBottom: $(dup).outerHeight() + 'px'});
        } else if (window.pageYOffset < offset) {
            approveBoxDuplicated.remove();
        }

        if (Math.round(window.pageYOffset) > Math.round(offsetBottom - threshold)) {
            $('.approved-box.dup').css({position: 'absolute', bottom: '0'});
        } else {
            $('.approved-box.dup').css({
                position: 'fixed',
                bottom: navBarBottom ? navBarBottom.outerHeight() + 'px' : '10px',});
        }
    });


    $('#expand-patient-assessment').on('click', function() {
        $('.patient-assessment-segment').toggleClass('show-all')
    })

    $(document).on('click', '.play-icon', function(e){
        e.preventDefault();
        const video = $(this).parents('.video').find('video')
        if(video) {
            $(this).hide();
            video[0].play()
            video.attr('controls', true)
        }
    })
})